//import * as DataObjects from "./APIDataObjects"
import { BaseSyntheticEvent } from "react";
import { Pagination } from "react-bootstrap";
import * as ReactDOM from 'react-dom';

export function Sleep(ms: number): Promise<unknown> {
    return new Promise((resolve: any) => setTimeout(resolve, ms));
}

// https://stackoverflow.com/questions/51086688/mutex-in-javascript-does-this-look-like-a-correct-implementation
class Mutex {

    current: Promise<unknown> = Promise.resolve();

    acquire(): Promise<() => void> {
        let _resolve: any;
        var p: Promise<unknown> = new Promise((resolve: any) => {
            _resolve = () => { resolve(); };
        });
        // Caller gets a promise that resolves when the current outstanding
        // lock resolves
        var rv: Promise<() => void> = this.current.then(() => { return _resolve; });
        // Don't allow the next request until the new promise is done
        this.current = p;
        // Return the new promise
        return rv;
    };

}

var mutex = new Mutex();
var active_click_func: ((() => Promise<void>) | null)[] = [null, null];

// Allow only one button event run at time
export async function LockButtonEvent_DropMode(async_func: () => Promise<void>, index: number = 0) {

    /*
    mutex.acquire().then((unlock:any)=>{
        try {
            if (active_click_func != null)
                return;
            active_click_func = async_func;
        } finally {
            unlock();
        }
        active_click_func().finally(() => { active_click_func = null; });    
    });
    */


    var unlock: (() => void) = await mutex.acquire();
    try {
        if (active_click_func[index] != null)
            return;
        active_click_func[index] = async_func;
    } finally {
        unlock();
    }
    async_func().finally(() => { active_click_func[index] = null; });
}

export async function LockButtonEvent_ForceRun(async_func: () => Promise<void>, index: number = 0) {

    /*
    mutex.acquire().then((unlock:any)=>{
        try {
            if (active_click_func != null)
                return;
            active_click_func = async_func;
        } finally {
            unlock();
        }
        active_click_func().finally(() => { active_click_func = null; });    
    });
    */

    var unlock: (() => void) = await mutex.acquire();
    try {
        while (active_click_func[index] != null)
            await Sleep(200);
        active_click_func[index] = async_func;
    } finally {
        unlock();
    }
    async_func().finally(() => { active_click_func[index] = null; });
}


export function createPaginationIndices(
    page:number, page_count:number,
    onClickGoToPage: (btn:BaseSyntheticEvent, page: number) => void
): JSX.Element[] {
    var pageArray: JSX.Element[] = [];

    if (page >= 0) {
        var init_page = 1;
        var end_page = 1;

        var dst_zero = page;
        var dst_last = (page_count - 1) - page;
        if (dst_zero < dst_last) {
            init_page = page + 1 - 2;
            if (init_page < 1)
                init_page = 1;
            end_page = init_page + 5;
            if (end_page > page_count + 1)
                end_page = page_count + 1;
        } else {
            end_page = page + 1 + 3;
            if (end_page > page_count + 1)
                end_page = page_count + 1;
            init_page = end_page - 5;
            if (init_page < 1)
                init_page = 1;
        }

        for (var i = init_page; i < end_page; i++) {
            ((real_index: number) => {
                if (real_index === page)
                    pageArray.push(<Pagination.Item onClick={(btn:BaseSyntheticEvent) => { onClickGoToPage(btn,real_index); }} key={"pagination_"+real_index.toString()} active>{real_index + 1}</Pagination.Item>);
                else
                    pageArray.push(<Pagination.Item onClick={(btn:BaseSyntheticEvent) => { onClickGoToPage(btn,real_index); }} key={"pagination_"+real_index.toString()}>{real_index + 1}</Pagination.Item>);
            })(i - 1);
        }
    }

    return pageArray;
}

export function getParentAnchorOrButtonElement(instance: HTMLElement | null):HTMLAnchorElement | HTMLButtonElement | null{
    while ( instance != null && 
        !(instance instanceof HTMLBodyElement) &&
        !(instance instanceof HTMLAnchorElement) &&
        !(instance instanceof HTMLButtonElement)
         ){
        instance = instance.parentElement;
    }
    if (instance != null){
        if (instance instanceof HTMLAnchorElement || instance instanceof HTMLButtonElement)
            return instance;
    }
    return null;
}

export function blurParentAnchorOrButtonElement(instance: HTMLElement | null){
    var anchor_parent = getParentAnchorOrButtonElement(instance);
    if (anchor_parent != null)
        anchor_parent.blur();
}

export function getActiveReactHTMLElement(): HTMLElement | null{
    var reactDomElement = ReactDOM.findDOMNode((document as any).activeElement);
    if (reactDomElement instanceof HTMLElement)
        return reactDomElement
    return null;
}


export function RedirectNewPage(location:string, args: { [key: string]:string } ) {
    var f = document.createElement('form');
    f.action=location;
    f.method='GET';
    f.target='_blank';
    for (var key in args) {
        var value = args[key];
        value = value.split('"').join('\\"')
        var i=document.createElement('input');
        i.type='hidden';
        i.name=key;
        i.value=value;//'<!DOCTYPE html>'+document.documentElement.outerHTML;
        f.appendChild(i);
    }

    document.body.appendChild(f);
    f.submit();
    //$('<form action="' + location + '" method="POST">' + form + '</form>').appendTo($(document.body)).submit();

    setTimeout(() => {
        document.body.removeChild(f);    
    }, 500);

}

export function setCookie(cname:string, cvalue:string, exsec:number) {
    const d = new Date();
    d.setTime(d.getTime() + (exsec*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname:string):string|null {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
