import { Dispatch, Fragment, SetStateAction, createContext, useContext, useEffect, useRef, useState } from "react";
import { API } from "./API";
import { RequestResult } from "./APIBase";
import loading_icon from "./resources/loading_icon.gif";
import { Modal } from "react-bootstrap";
import DrawAlertMessages from "./Pages/Layout/DrawAlertMessages";
//import * as Util from "./Util";

export enum AppStateMessageType {
    Error,
    Info,
    Success,
    Warning,
}

export interface AppStateMessage {
    type: AppStateMessageType;
    txt: string;
}

export function createAppMessageErrorFromString(txt: string): AppStateMessage {
    return {
        type: AppStateMessageType.Error,
        txt: txt,
    } as AppStateMessage;
}

export function createAppMessageErrorFromCatch(err: any): AppStateMessage {
    var str_error_msg: string = "";
    if (err instanceof RequestResult) {
        console.error(err.error, err.body);
        try {
            var json_parsed = JSON.parse("" + err.error?.trim());
            if ("errors" in json_parsed) {
                for (var key in json_parsed.errors) {
                    if (typeof json_parsed.errors[key] === 'string'){
                        if (str_error_msg !== "")
                            str_error_msg += "\n";
                        str_error_msg += json_parsed.errors[key];
                    } else if (Array.isArray(json_parsed.errors[key])){
                        for (var errK of json_parsed.errors[key]) {
                            if (str_error_msg !== "")
                                str_error_msg += "\n";
                            str_error_msg += errK;
                        }
                    }
                }
            }
            if (str_error_msg === "")
                str_error_msg = json_parsed.message;
        } catch (parser_err: any) {
            str_error_msg = "" + err.error?.trim();
        }
    } else if (err instanceof Error) {
        console.error(err.message);
        str_error_msg = err.message;
    } else {
        console.error(err);
        str_error_msg = err.toString();
    }

    return createAppMessageErrorFromString(str_error_msg);
}

export function textNewlineBreak(txt: string): JSX.Element[] {
    var count = 0;
    var result: JSX.Element[] = [];
    var splits: string[] = txt.split('\n');
    for (var i = 0; i < splits.length; i++) {
        if (i > 0) {
            result.push(
                <br key={count} />
            );
            count++;
        }

        result.push(
            <Fragment key={count}>{splits[i]}</Fragment>
        );

        count++;
    }
    return result;
}

export interface AppState {

    // true when application is in booting state
    isInitializing: boolean;

    // true when the user is logged in
    isLoggedIn: boolean;

    // true if is to show the loading modal
    isToShowLoadingModal: boolean;

    // the app message list
    appMessages: AppStateMessage[];
}

interface AppStateContextData {
    appState: AppState;
    setAppState: Dispatch<SetStateAction<AppState>>;
}

export var AppStateContext = createContext(null as AppStateContextData | null);

export function useAppState(): AppState {
    var ctx = useContext(AppStateContext);
    if (ctx == null)
        throw new Error("useAppState() may be used only in the context of a <AppStateComponent> component.");
    // return a copy of appState
    //return Object.assign({},ctx.appState);
    return ctx.appState;
}

export function useSetAppState(): Dispatch<SetStateAction<AppState>> {
    var ctx = useContext(AppStateContext);
    if (ctx == null)
        throw new Error("useSetAppState() may be used only in the context of a <AppStateComponent> component.");
    return ctx.setAppState;
    // return (value:SetStateAction<AppState>)=>{
    //     if (ctx == null)
    //         throw new Error("useSetAppState() null ctx reference error.");
    //     ctx.setAppState(Object.assign({},value));
    // };
}

export function AppStateComponent({ children }: { children: any }): JSX.Element {

    // initialize app state structure
    var [appState, setAppState_react] = useState({
        isInitializing: true,
        isLoggedIn: false,
        isToShowLoadingModal: false,
        appMessages: [],
    } as AppState);

    // initialize the appState with diferent reference from the current state
    appState = Object.assign({}, appState);
    // always copy the input parameter on appSetState...
    // Allow to use setAppState directly with the appState from useAppState function.
    var setAppState: Dispatch<SetStateAction<AppState>> = (value: SetStateAction<AppState>) => {
        setAppState_react(Object.assign({}, value));
    }

    var isFirstExecutionRef = useRef(true);

    // update the global controller
    // useEffect acts like componentDidMount
    useEffect(() => {
        if (isFirstExecutionRef.current) {
            isFirstExecutionRef.current = false;
            (async () => {
                try {
                    //await Util.Sleep(2000);
                    appState.isLoggedIn = await API.auth.check();
                    appState.isInitializing = false;
                } catch (err: any) {
                    appState.appMessages.push(createAppMessageErrorFromCatch(err));
                }
                setAppState(appState);
            })();
        }
    });

    return (
        <>
            {appState.isToShowLoadingModal && (
                <Modal show={true} animation={false} centered={true} contentClassName="bg-transparent border-0">
                    <img className="m-auto" width={47.5} src={loading_icon} alt="loading" style={{userSelect:"none"}}></img>
                </Modal>
            )}
            <AppStateContext.Provider value={{ appState: appState, setAppState: setAppState } as AppStateContextData}>
                {appState.isInitializing ? (
                    <>
                        <DrawAlertMessages className="pt-1" />
                        <img className="m-auto" width={47.5} src={loading_icon} alt="loading" style={{userSelect:"none"}}></img>
                    </>
                ) : (
                    <>
                        {children}
                    </>
                )}
            </AppStateContext.Provider>
        </>
    );
}