import { Alert, Container } from "react-bootstrap";
import { AppStateMessage, AppStateMessageType, textNewlineBreak, useAppState, useSetAppState } from "../../AppState";
import { FaExclamationCircle, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";
import { blurParentAnchorOrButtonElement, getActiveReactHTMLElement, getCookie, setCookie } from "../../Util";
import { useEffect, useRef } from "react";

export interface DrawAlertMessagesProps extends React.HTMLAttributes<HTMLElement>{

}

export default function DrawAlertMessages(props:DrawAlertMessagesProps): JSX.Element {

    var appState = useAppState();
    var setAppState = useSetAppState();
    var runOnceRef = useRef(true);

    var COOKIE_MSG = "Utilizamos cookies para garantir a melhor experiência em nosso site. Se você continuar a usar este site, assumiremos que você concorda com o uso do cookie.";

    function OnCloseAlert(index:number){
        appState.appMessages.splice(index,1);
        setAppState(appState);
        blurParentAnchorOrButtonElement(getActiveReactHTMLElement());
    };

    useEffect(()=>{

        var cookie_msg:string|null = null;
        if (runOnceRef.current){
            runOnceRef.current = false;

            //check the sessionstorage
            //var cookie_msg = sessionStorage.getItem("cookie_msg");
            cookie_msg = getCookie("COOKIE_MSG");
            if (cookie_msg == null){
                cookie_msg = COOKIE_MSG;

                var msg_found = false;
                for(var msg of appState.appMessages){
                    if (msg.txt === cookie_msg){
                        msg_found = true;
                        break;
                    }
                }

                if (!msg_found){
                    appState.appMessages.push({
                        type: AppStateMessageType.Info,
                        txt: cookie_msg
                    } as AppStateMessage);
                    setAppState(appState);
                }
            }
        }

        if (appState.appMessages.length === 0){
            //var cookie_msg = sessionStorage.getItem("cookie_msg");
            cookie_msg = getCookie("COOKIE_MSG");
            if (cookie_msg == null){
                //sessionStorage.setItem("cookie_msg", COOKIE_MSG);
                setCookie("COOKIE_MSG", COOKIE_MSG, 10*24*60*60);//10 days
            }
        }
    });

    var messages = appState.appMessages.slice(0).reverse().map((object:AppStateMessage, i) => {
        var real_index = appState.appMessages.length-1-i;
        var icon = <FaExclamationTriangle />;
        var variant_msg = "danger";
        if (object.type === AppStateMessageType.Error){
            icon = <FaExclamationTriangle />;
            variant_msg = "danger";
        } else if (object.type === AppStateMessageType.Info){
            icon = <FaInfoCircle />;
            variant_msg = "info";
        } else if (object.type === AppStateMessageType.Success){
            icon = <FaExclamationCircle />;
            variant_msg = "success";
        } else if (object.type === AppStateMessageType.Warning){
            icon = <FaExclamationTriangle />;
            variant_msg = "warning";
        }

        return <Alert key={i} className="d-flex mb-1" style={{zIndex:9999}} show={true}  onClose={() => { OnCloseAlert(real_index); }} variant={variant_msg} dismissible >
            <div className="me-2 d-flex align-items-center">{icon}</div>
            <div>{textNewlineBreak(object.txt)}</div>
        </Alert>;
    });

    return <Container {...props} style={{ height: "0px" }}>
        {(appState.appMessages.length > 0) && (
            <div className="" style={{ height: "0px" }}>
                {messages}
            </div>
        )}
    </Container>;


}