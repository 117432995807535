import { Container, } from "react-bootstrap";

/*
<footer className="footer mt-auto py-3 bg-body-tertiary">
                <Container>
                    <span className="text-body-secondary">Place sticky footer content here.</span>
                </Container>
            </footer>
 */
export default function Footer(): JSX.Element {
    return (
        <footer className="footer mt-auto py-3">
            <Container>
                <p className="text-center text-body-secondary border-top my-0">© {(new Date()).getFullYear()} Alessandro Ribeiro & Samuel Augusto</p>
            </Container>
        </footer>
    );
}