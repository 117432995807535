import { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
    FaArrowDown,
    FaArrowUp,
    FaEye,
    FaPlus,
    FaTrash,
} from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API } from "../API";
import * as DataObjects from "../APIDataObjects";
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState";
import loading_icon from "../resources/loading_icon.gif";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";

export default function MusicSlide(): JSX.Element {

    var appState = useAppState();
    var setAppState = useSetAppState();
    var navigate = useNavigate();

    var params = useParams();
    var loadedIDRef = useRef("-1");

    var location = useLocation();

    //var stringTextToPreviewRef = useRef("");
    var [showModal, setShowModal] = useState(false);
    var [showTrashModal, setShowTrashModal] = useState(false);

    var music: DataObjects.Music = {
        id: -1,
        name: "",
        authors: [],
        slides: [],
        chords: "",
        tone: "",
    } as DataObjects.Music;

    if (location.state != null) {
        music = location.state;
    }


    // var [music, setMusic] = useState({
    //     id: -1,
    //     name: "",
    //     authors: [],
    //     slides: [],
    //     chords: "",
    //     tone: "",
    // } as DataObjects.Music);


    var loadCallback = useCallback(async (id: number) => {
        console.log("loadCallback: ", id);

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            var newData: (DataObjects.Music | null)[] = await API.music.get({ id_list: [id] } as DataObjects.JSON_id_list_request);

            if (newData.length > 0 && newData[0] !== null) {

                //setMusic(newData[0]);

                navigate(".", {
                    replace: true, state: newData[0]
                });

            }
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);


    }, [appState, setAppState, navigate]);

    useEffect(() => {
        if (appState.isInitializing)
            return;
        if (!appState.isLoggedIn) {
            navigate("/login");
            return;
        }

        if (location.state == null && params.id != null && loadedIDRef.current !== params.id) {
            loadedIDRef.current = params.id;
            loadCallback(parseInt(params.id));
        }

        return () => {
            //saveHistory();
        }
    });

    function saveHistory() {
        navigate(".", { replace: true, state: Object.assign({}, music) });
    }

    var saveSlidesCallback = useCallback(async (_music: DataObjects.Music) => {
        if (_music.id <= -1)
            return;
        console.log("saveSlidesCallback: ", _music);

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            await API.music.save_slides(
                {
                    id: _music.id,
                    slides: _music.slides,
                } as DataObjects.JSON_music_save_slides_request
            );

            //navigate(`/music`);
            navigate(`/music`, {
                state: {
                    musicIdToShow: _music.id
                }
            });

        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);
    }, [appState, setAppState, navigate]);

    var modal_image_ref = useRef(loading_icon as any);

    var modal_delete_ref = useRef(-1 as number)

    var add_slide_ref = useRef(-1 as any);

    var previewText = useCallback(async (_slide: DataObjects.Slide) => {
        console.log("previewText: ", _slide.text);

        setShowModal(true);

        modal_image_ref.current = loading_icon;

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            var img_embedded_src = await API.music.preview_slide(
                {
                    text: _slide.text,
                    size_pt: _slide.size_pt,
                } as DataObjects.JSON_music_preview_slide_request
            );
            modal_image_ref.current = img_embedded_src;
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);
    }, [appState, setAppState, setShowModal]);

    function onclickBackButton() {
        if (loadedIDRef.current === "-1")
            navigate("/music")
        else
            navigate(`/music`, {
                state: {
                    musicIdToShow: parseInt(loadedIDRef.current),
                },
            })
    }

    return (
        <>
            <Modal show={showTrashModal} onHide={() => setShowTrashModal(false)}>
                <Modal.Header>
                    <Modal.Title>Remover</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Deseja apagar o slide #{modal_delete_ref.current + 1}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTrashModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => { music.slides.splice(modal_delete_ref.current, 1); saveHistory(); setShowTrashModal(false); }}>
                        Apagar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered={false} show={showModal} onHide={() => { setShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Visualizar</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex content-justify-center">
                    <img className="w-100" src={modal_image_ref.current} style={{ aspectRatio: 640.0 / 480.0, objectFit: "scale-down", userSelect: "none" }} alt="loading" ></img>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header title="Cristo Ilumina" />
            <main>
                <Container className="my-3 col-md-8 col-lg-6">
                    <Row className="mb-3">
                        <Col >
                            <div className="h3 mb-3 text-start">Slides</div>
                            <div>Música: {music.name}</div>
                            {music.authors.map(function (author: string, i) {
                                return <div key={i}> - {author}</div>
                            })}
                        </Col>
                    </Row>
                    {music.slides.map(function (slide: DataObjects.Slide, i) {
                        return (
                            <Form key={i}>
                                <Form.Group className="mt-4" controlId="script">
                                    <Row xs={12} className="mx-1">
                                        <Col xs="auto" className="mx-0 px-0" >
                                            <Form.Label>Slide #{i + 1}</Form.Label>
                                        </Col>
                                        <Col className="d-flex align-self-start justify-content-end mx-0 px-0">
                                            <Row className="gx-1 d-flex align-items-stretch">
                                                <Col xs="auto" className="d-flex align-items-center">
                                                    <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-font_size-" + i.toString()}>Tamanho da Fonte</Tooltip>}>
                                                        <Form.Select size="sm" style={{boxShadow:'none'}} className="" aria-label="FontSize" value={slide.size_pt} onChange={(slc:BaseSyntheticEvent)=>{ slide.size_pt = parseInt(slc.target.value); saveHistory(); }}>
                                                            {[...Array(21)].map(function (obj, i) {
                                                                var real_pt = i * 2 + 30;
                                                                return <option key={i} value={real_pt} >{real_pt}pt</option>;
                                                            })}
                                                        </Form.Select>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-visualizar-" + i.toString()}>Visualizar</Tooltip>}>
                                                        <Button onClick={async () => { await previewText(slide); }} size="sm" variant="success">
                                                            <div className="d-flex align-items-center">
                                                                <FaEye size={16} />
                                                            </div>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-remover-" + i.toString()}>Remover</Tooltip>}>
                                                        <Button onClick={() => { modal_delete_ref.current = i; setShowTrashModal(true) }} size="sm" variant="danger">
                                                            <div className="d-flex align-items-center">
                                                                <FaTrash size={16} />
                                                            </div>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-moveup-" + i.toString()}>Mover para cima</Tooltip>}>
                                                        <Button disabled={(i <= 0)} onClick={() => { var aux = music.slides[i]; music.slides[i] = music.slides[i - 1]; music.slides[i - 1] = aux; saveHistory(); }} size="sm" className="border-0 bg-warning">
                                                            <div className="d-flex align-items-center">
                                                                <FaArrowUp size={18} color="black" />
                                                            </div>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                                <Col className="d-flex align-items-center">
                                                    <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-movedown-" + i.toString()}>Mover para baixo</Tooltip>}>
                                                        <Button disabled={(i >= music.slides.length - 1)} onClick={() => { var aux = music.slides[i]; music.slides[i] = music.slides[i + 1]; music.slides[i + 1] = aux; saveHistory(); }} size="sm" className=" border-0 bg-warning">
                                                            <div className="d-flex align-items-center">
                                                                <FaArrowDown size={18} color="black" />
                                                            </div>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Conteúdo slide"
                                        className="border-1 border-black shadow-success"
                                        value={slide.text}
                                        onChange={(v: BaseSyntheticEvent) => { slide.text = v.target.value; saveHistory(); }}
                                    />
                                </Form.Group>
                            </Form>
                        )
                    })}
                    <Row className="mt-3">
                        <Col xs={"auto"} className="d-flex align-self-end ms-auto">
                            <Button id="button_add_slide" ref={add_slide_ref} onClick={() => {
                                var next_size = 44;
                                if (music.slides.length > 0)
                                    next_size = music.slides[music.slides.length-1].size_pt;
                                music.slides.push({
                                    text: "",
                                    size_pt: next_size
                                } as DataObjects.Slide);
                                saveHistory();
                                setTimeout(() => {
                                    add_slide_ref.current?.scrollIntoView({ behavior: "smooth" });
                                }, 300);
                            }} className="d-flex align-items-center">
                                <div className="d-flex pe-1">
                                    <FaPlus />
                                </div>
                                Adicionar Slide
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mx-auto mt-3 gx-1 d-flex justify-content-center">
                        <Col xs={"auto"}>
                            <Button variant="secondary" onClick={onclickBackButton}>
                                Voltar
                            </Button>
                        </Col>
                        <Col xs={"auto"}>

                            <Button onClick={async () => { await saveSlidesCallback(music); }}
                                variant="success">
                                Salvar
                            </Button>

                        </Col>
                    </Row>
                    {/* <Row className="mt-2">
                        <Col className="d-flex align-self-start justify-content-center">
                            <Button
                                onClick={async () => { await saveSlidesCallback(music); }}
                                variant="success"
                                className="px-3 d-flex align-items-center"
                            >
                                <div className="d-flex pe-1">
                                    <FaCheck />
                                </div>
                                Salvar
                            </Button>
                        </Col>
                    </Row> */}
                </Container>
            </main>
            <Footer />
        </>
    )
}
