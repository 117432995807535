import React, { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react"
import { Button, Col, Container, Modal, OverlayTrigger, Pagination, Row, Tooltip } from "react-bootstrap"
import { FaDesktop, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import { API } from "../API"
import * as DataObjects from "../APIDataObjects"
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState"
import { blurParentAnchorOrButtonElement, createPaginationIndices } from "../Util"
import Footer from "./Layout/Footer"
import Header from "./Layout/Header"
import "./Music.css"

function useQuery(): URLSearchParams {
    var location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export default function Music(): JSX.Element {
    //var appState: AppState = useAppState();
    // var elements: JSX.Element[] = [];
    // for (var i = 0; i < 100; i++) {
    //     elements.push(
    //         (<>
    //             <Row className="justify-content-center">
    //                 <Col md="auto"><img src="./logo.png" alt="logo"></img></Col>
    //                 <Col md="auto" className="align-middle my-auto" style={{ textAlign: "left" }}>
    //                     <h2>Cristo Ilumina<br />Assistente Multimídia</h2>
    //                 </Col>
    //             </Row>
    //         </>)
    //     );
    // }

    var navigate = useNavigate();
    var appState = useAppState();
    var setAppState = useSetAppState();
    var query = useQuery();
    var [showModal, setShowModal] = useState(false);
    var musicToRemoveRef = useRef({
        id: -1,
        name: "",
        authors: [],
        slides: [],
        chords: "",
        tone: ""
    } as DataObjects.Music);

    var location = useLocation();

    var [currentPage, setCurrentPage] = useState({
        page: -1,
        max_per_page: 0,
        page_count: 0,
        music_count: 0,
        musics: [] as DataObjects.Music[]
    } as DataObjects.JSON_music_list_response);

    function onClickAddMusic() {
        // var param = {
        //     idToLoad: undefined
        // } as MusicEditState;

        //navigate('/music/edit', { state: param });

        navigate('/music/edit');
    }

    var asyncLoadCallback = useCallback(async (page: number) => {
        console.log("asyncLoadCallback: ", page);

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {

            var page_to_load = page;
            //console.log("location.state: ",location.state);
            if (location.state != null && location.state.musicIdToShow != null) {
                //search for page ID
                //do search and navigete with the returned page


                console.log("... redirecting ...");

                var rc = await API.music.which_page({
                    id: parseInt(location.state.musicIdToShow),
                    max_per_page: 5,
                } as DataObjects.JSON_which_page_request);
                page_to_load = rc.page;

                //console.log("redirecting to page: ", page_to_load);

                navigate(`/music?page=${page_to_load}`, { replace: true, state: null });
            }

            if (page_to_load === page) {

                var newPage = await API.music.list({
                    page: page,
                    max_per_page: 5,
                } as DataObjects.JSON_list_request);
                setCurrentPage(newPage);

            }

            //navigate(`/music?page=${page}`);
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);
    }, [appState, setAppState, setCurrentPage, location, navigate]);

    var deleteMusic = useCallback(async (id: number) => {

        console.log("deleteMusic: ", id);

        var need_reload = false;

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            await API.music.delete({
                id_list: [id]
            } as DataObjects.JSON_id_list_request);

            need_reload = true;

        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);

        if (need_reload)
            await asyncLoadCallback(currentPage.page);

    }, [appState, setAppState, asyncLoadCallback, currentPage]);

    useEffect(
        () => {
            if (appState.isInitializing)
                return;
            if (!appState.isLoggedIn) {
                navigate("/login");
                return;
            }

            var intPage: number = 0;
            var queryPage: string | null = query.get("page");
            if (queryPage == null) {
                //navigate(`/music?page=0`);
                navigate(`/music?page=0`, { replace: true, state: location.state });
                return;
            }
            intPage = parseInt(queryPage);

            if (intPage !== currentPage.page) {

                //console.log("page different: ", intPage, currentPage.page);

                // avoid infinity reload with different page number...
                currentPage.page = intPage;
                setCurrentPage(Object.assign({}, currentPage));

                asyncLoadCallback(intPage);
            }
        },
        [appState, setCurrentPage, asyncLoadCallback, currentPage, navigate, query, location]
    );


    var pageArray = createPaginationIndices(
        currentPage.page, currentPage.page_count,
        (btn: BaseSyntheticEvent, page: number) => {
            blurParentAnchorOrButtonElement(btn.target);
            navigate(`/music?page=${page}`);
            window.scrollTo(0, 0);
        }
    );
    var pagePrevious = useCallback((btn: BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page > 0) {
            query.set("page", (currentPage.page - 1).toString());
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
            window.scrollTo(0, 0);
        }
    }, [currentPage, query, location, navigate]);
    var pageNext = useCallback((btn: BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page < currentPage.page_count - 1) {
            query.set("page", (currentPage.page + 1).toString());
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
            window.scrollTo(0, 0);
        }
    }, [currentPage, query, location, navigate]);

    // var pageArray = [];

    // if (currentPage.page >= 0) {
    //     var init_page = 1;
    //     var end_page = 1;

    //     var dst_zero = currentPage.page;
    //     var dst_last = (currentPage.page_count - 1) - currentPage.page;
    //     if (dst_zero < dst_last) {
    //         init_page = currentPage.page + 1 - 2;
    //         if (init_page < 1)
    //             init_page = 1;
    //         end_page = init_page + 5;
    //         if (end_page > currentPage.page_count + 1)
    //             end_page = currentPage.page_count + 1;
    //     } else {
    //         end_page = currentPage.page + 1 + 3;
    //         if (end_page > currentPage.page_count + 1)
    //             end_page = currentPage.page_count + 1;
    //         init_page = end_page - 5;
    //         if (init_page < 1)
    //             init_page = 1;
    //     }

    //     for (var i = init_page; i < end_page; i++) {
    //         ((real_index: number) => {
    //             if (real_index === currentPage.page)
    //                 pageArray.push(<Pagination.Item onClick={() => { navigate(`/music?page=${real_index}`); }} key={real_index} active>{real_index + 1}</Pagination.Item>);
    //             else
    //                 pageArray.push(<Pagination.Item onClick={() => { navigate(`/music?page=${real_index}`); }} key={real_index}>{real_index + 1}</Pagination.Item>);
    //         })(i - 1);
    //     }
    // }

    return (
        <>
            <Modal centered={false} show={showModal} onHide={() => { setShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Remover</Modal.Title>
                </Modal.Header>
                <Modal.Body>Deseja remover a música '{musicToRemoveRef.current.name}' ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={async () => { setShowModal(false); await deleteMusic(musicToRemoveRef.current.id); }}>
                        Remover
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header title="Cristo Ilumina" />
            <main className="flex-shrink-0">
                {/* <Container className="px-3 pb-3 pt-3-navbar-spacer"> */}
                <Container className="pt-3">
                    <Row className="pb-3">
                        <Col className="pe-0">
                            <h2>Músicas</h2>
                        </Col>
                        <Col>
                            <div className="d-flex flex-row-reverse">
                                <Button onClick={onClickAddMusic} className="d-flex align-items-center">
                                    <div className="d-flex align-items-center pe-2">
                                        <FaPlus />
                                    </div>
                                    Adicionar Música
                                </Button>
                            </div>
                        </Col>
                    </Row>

                    <Row className="header-table fw-bold py-0" style={{ backgroundColor: "#e2e3e5" }}>
                        <Col xs={6} md={8} lg={9}
                            className="py-2 d-flex align-items-center">
                            Nome
                        </Col>

                        <Col xs={6} md={4} lg={3}
                            className="d-flex justify-content-center border-start border-secondary py-2">
                            Ações
                        </Col>
                    </Row>
                    {currentPage.musics.map(function (music: DataObjects.Music, i) {
                        var authors = "";
                        for (var author of music.authors) {
                            if (authors.length > 0)
                                authors = authors + ", " + author;
                            else
                                authors = author;
                        }
                        if (authors.length === 0)
                            authors = "Autor não cadastrado";
                        return (
                            <Row key={i} className="py-0 table-music align-items-stretch">
                                <Col xs={6} md={8} lg={9} className="fs-6 py-0">
                                    <div className="pt-2">{music.name}</div>
                                    <div className="text-secondary text-truncate d-block small" >{authors}</div>
                                    <div className="text-secondary text-truncate d-block small pb-2" >Possui {music.slides.length} slide(s).</div>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="py-0 border-start border-secondary d-flex align-items-center justify-content-center">
                                    <Row xs={"auto"} className="py-2 gx-1 d-flex justify-content-center align-items-baseline">
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-slides-" + music.id.toString()}>Slides</Tooltip>}>
                                                <Button onClick={() => { navigate(`/music/slide/${music.id}`); }} className="border-0 text-success bg-light">
                                                    <FaDesktop size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-edit-" + music.id.toString()}>Editar</Tooltip>}>
                                                <Button onClick={() => { navigate(`/music/edit/${music.id}`); }} className="border-0 text-success bg-light">
                                                    <FaPencilAlt size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-remove-" + music.id.toString()}>Remover</Tooltip>}>
                                                <Button onClick={() => { musicToRemoveRef.current = music; setShowModal(true); }} className="border-0 text-danger bg-light">
                                                    <FaTrash size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}

                    <Row className="py-4">
                        <Col className="d-flex flex-row-reverse">
                            <Button onClick={onClickAddMusic} className="d-flex align-items-center">
                                <div className="d-flex align-items-center pe-2">
                                    <FaPlus />
                                </div>
                                Adicionar Música
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col
                            xs={12}
                            md={6}
                            className="pb-2 d-flex justify-content-md-start justify-content-center"
                        >
                            <div>
                                Mostrando <b>{currentPage.musics.length}</b> de <b>{currentPage.music_count}</b> músicas.
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-md-end justify-content-center">
                            <Pagination>
                                <Pagination.Prev onClick={pagePrevious} />
                                {pageArray}
                                <Pagination.Next onClick={pageNext} />
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    )
}
