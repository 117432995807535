import { BaseSyntheticEvent, useCallback, useEffect, useRef } from "react"
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { FaPlus } from "react-icons/fa"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { API } from "../API"
import * as DataObjects from "../APIDataObjects"
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState"
import Footer from "./Layout/Footer"
import Header from "./Layout/Header"

// export interface MusicEditState {
//     // create if 'idToLoad' is null
//     idToLoad?: number;
// };

export default function MusicEdit(): JSX.Element {
	// route to edit a music by its ID
	var appState = useAppState()
	var setAppState = useSetAppState()
	var navigate = useNavigate()

	var location = useLocation()
	var params = useParams()
	var loadedIDRef = useRef("-1")

	var isToCreate = params.id == null

	// var [musicHeader, setMusicHeader] = useState({
	//     name: "",
	//     authors: []
	// } as DataObjects.JSON_music_create_request)

	var musicHeader = {
		id: -1,
		name: "",
		authors: [],
	} as DataObjects.JSON_music_save_header_request

	if (location.state != null && location.state !== musicHeader) {
		musicHeader = Object.assign({}, location.state)
		loadedIDRef.current = musicHeader.id.toString()
	}

	var loadCallback = useCallback(
		async (id: number) => {
			console.log("loadCallback: ", id)

			appState.isToShowLoadingModal = true
			setAppState(appState)

			try {
				var newData: (DataObjects.Music | null)[] = await API.music.get({
					id_list: [id],
				} as DataObjects.JSON_id_list_request)

				if (newData.length > 0 && newData[0] !== null) {
					navigate(".", {
						replace: true,
						state: {
							id: newData[0].id,
							name: newData[0].name,
							authors: newData[0].authors,
						} as DataObjects.JSON_music_save_header_request,
					})
				}
			} catch (err: any) {
				appState.appMessages.push(createAppMessageErrorFromCatch(err))
			}

			appState.isToShowLoadingModal = false
			setAppState(appState)
		},
		[appState, setAppState, navigate]
	)

	var createCallback = useCallback(
		async (_musicHeader: DataObjects.JSON_music_save_header_request) => {
			console.log("createCallback: ", _musicHeader)

			appState.isToShowLoadingModal = true
			setAppState(appState)

			try {
				var created_music = await API.music.create({
					name: _musicHeader.name,
					authors: _musicHeader.authors,
				} as DataObjects.JSON_music_create_request)

				navigate(`/music`, {
					state: {
						musicIdToShow: created_music.id,
					},
				})
			} catch (err: any) {
				appState.appMessages.push(createAppMessageErrorFromCatch(err))
			}

			appState.isToShowLoadingModal = false
			setAppState(appState)
		},
		[appState, setAppState, navigate]
	)

	var saveHeaderCallback = useCallback(
		async (_musicHeader: DataObjects.JSON_music_save_header_request) => {
			if (params.id == null) return
			console.log("saveHeaderCallback: ", _musicHeader)

			appState.isToShowLoadingModal = true
			setAppState(appState)

			try {
				await API.music.save_header({
					id: parseInt(params.id),
					name: _musicHeader.name,
					authors: _musicHeader.authors,
				} as DataObjects.JSON_music_save_header_request)

				//navigate(`/music`);
				navigate(`/music`, {
					state: {
						musicIdToShow: parseInt(params.id),
					},
				})
			} catch (err: any) {
				appState.appMessages.push(createAppMessageErrorFromCatch(err))
			}

			appState.isToShowLoadingModal = false
			setAppState(appState)
		},
		[appState, setAppState, navigate, params]
	)

	useEffect(() => {
		if (appState.isInitializing) return
		if (!appState.isLoggedIn) {
			navigate("/login")
			return
		}

		if (!isToCreate && params.id != null && loadedIDRef.current !== params.id) {
			loadedIDRef.current = params.id
			loadCallback(parseInt(params.id))
		}

		return () => {
			//saveHistory();
		}
	})

	function saveHistory() {
		navigate(".", { replace: true, state: Object.assign({}, musicHeader) })
	}

	function onclickBackButton() {
		if (loadedIDRef.current === "-1") 
            navigate("/music")
		else
			navigate(`/music`, {
				state: {
					musicIdToShow: parseInt(loadedIDRef.current),
				},
			})
	}

	return (
		<>
			<Header title="Cristo Ilumina" />
			<main>
				<Container className="p-3 col-md-6">
					{isToCreate ? (
						<div className="h3 mb-3 text-center">Adicionar Música</div>
					) : (
						<div className="h3 mb-3 text-center">Editar Música</div>
					)}
					<Form>
						<Form.Group className="form-floating" controlId="music_name">
							<Form.Control
								type="text"
								placeholder="Nome da música"
								value={musicHeader.name}
								onChange={(v: BaseSyntheticEvent) => {
									musicHeader.name = v.target.value
									saveHistory()
								}}
							/>
							<Form.Label>Nome da música</Form.Label>
						</Form.Group>
						<Form.Label className="my-3 h5 fw-normal">Autor(es):</Form.Label>
						{musicHeader.authors.map(function (authorName: string, i) {
							return (
								<Row key={i} className="my-1 d-flex align-items-center">
									<Col>
										<Form.Group
											className="form-floating"
											controlId="music_name">
											<Form.Control
												type="text"
												placeholder="Nome da música"
												onChange={(v: BaseSyntheticEvent) => {
													musicHeader.authors[i] =
														v.target.value
													saveHistory()
												}}
												value={authorName}
											/>
											<Form.Label>Nome do autor</Form.Label>
										</Form.Group>
									</Col>
									<Col xs={"auto"} className="ps-0">
										<OverlayTrigger
											trigger={["hover", "hover"]}
											overlay={
												<Tooltip
													style={{
														position: "fixed",
													}}
													id={
														"tooltip-disabled-remove-" +
														i.toString()
													}>
													Remover
												</Tooltip>
											}>
											<Button
												onClick={() => {
													musicHeader.authors.splice(i, 1)
													saveHistory()
												}}
												variant="danger"
												className="d-flex align-items-center mx-auto">
												<div
													style={{
														fontFamily: "Arial",
													}}
													className="d-flex align-items-center p-1 fs-5">
													X
												</div>
											</Button>
										</OverlayTrigger>
									</Col>
								</Row>
							)
						})}

						<Button
							onClick={() => {
								musicHeader.authors.push("")
								saveHistory()
							}}
							className="mt-3 d-flex align-items-center ms-auto">
							<div className="d-flex align-items-center pe-2">
								<FaPlus />
							</div>
							Adicionar Autor
						</Button>
						<Row className="mx-auto mt-3 gx-1 d-flex justify-content-center">
							<Col xs={"auto"}>
								<Button variant="secondary" onClick={onclickBackButton}>
									Voltar
								</Button>
							</Col>
							<Col xs={"auto"}>
								{isToCreate ? (
									<Button onClick={async () => { await createCallback(musicHeader) }}
										variant="success">
										Salvar
									</Button>
								) : (
									<Button
										onClick={async () => {
											await saveHeaderCallback(musicHeader)
										}}
										variant="success">
										Atualizar
									</Button>
								)}
							</Col>
						</Row>
					</Form>
				</Container>
			</main>
			<Footer />
		</>
	)
}
