import { RequestResult, request_fetch, parseJSON } from "./APIBase";
import * as DataObjects from "./APIDataObjects";
import { RedirectNewPage, getCookie } from "./Util";

// EXAMPLE OF USE
//
// try {
//     await API.auth.login(user, pass);
// } catch (err: any) {
//     if (err instanceof RequestResult) {
//         console.error(err.error, err.body);
//         //var errorMessage: string = "" + err.error?.trim();
//     } else if (err instanceof Error) {
//         console.error(err.message);
//     } else {
//         console.error(err);
//     }
// }

function API_HEADERS_POST(): { [name: string]: string } {
    var result: { [name: string]: string } = {};
    result["Content-Type"] = "application/json";
    result["Accept"] = "application/json";
    var xsrfToken = getCookie("XSRF-TOKEN");
    if (xsrfToken != null && xsrfToken.length > 0)
        result["X-Xsrf-Token"] = xsrfToken;
    return result;
}

const API_HEADERS_GET: { [name: string]: string } = {
    //"Content-Type": "application/json",
    "Accept": "application/json"
};

class APIAuth {

    private api_root: string;

    constructor(api_root: string) {
        this.api_root = api_root;
    }

    async update_xsrf_token(): Promise<void> {
        var result: RequestResult = await request_fetch("GET", this.api_root + "/sanctum/csrf-cookie", "", API_HEADERS_GET);
        if (result.status >= 400)
            return Promise.reject(result);
    }

    async login(request: DataObjects.JSON_auth_login_request): Promise<DataObjects.JSON_auth_user> {
        await this.update_xsrf_token();
        var result: RequestResult = await request_fetch("POST", this.api_root + "/auth/login", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async logout(): Promise<void> {
        var result: RequestResult = await request_fetch("GET", this.api_root + "/auth/logout", "", API_HEADERS_GET);
        if (result.status >= 400)
            return Promise.reject(result);
    }

    async check(): Promise<boolean> {
        var result: RequestResult = await request_fetch("GET", this.api_root + "/auth/check", "", API_HEADERS_GET);
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async user(): Promise<DataObjects.JSON_auth_user> {
        var result: RequestResult = await request_fetch("GET", this.api_root + "/auth/user", "", API_HEADERS_GET);
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }
}

class APIMusic {

    private api_root: string;

    constructor(api_root: string) {
        this.api_root = api_root;
    }

    async create(request: DataObjects.JSON_music_create_request): Promise<DataObjects.Music> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/create", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async delete(request: DataObjects.JSON_id_list_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/delete", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async get(request: DataObjects.JSON_id_list_request): Promise<(DataObjects.Music | null)[]> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/get", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async list(request: DataObjects.JSON_list_request): Promise<DataObjects.JSON_music_list_response> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/list", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async search(request: DataObjects.JSON_search_request): Promise<DataObjects.JSON_music_list_response> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/search", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async which_page(request: DataObjects.JSON_which_page_request): Promise<DataObjects.JSON_which_page_response> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/which_page", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async save_header(request: DataObjects.JSON_music_save_header_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/save_header", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async save_chords(request: DataObjects.JSON_music_save_chords_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/save_chords", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async save_slides(request: DataObjects.JSON_music_save_slides_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/save_slides", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async preview_slide(request: DataObjects.JSON_music_preview_slide_request): Promise<string> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/music/preview_slide", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }
}

export enum SlideMode {
    Dark,
    Light
};

class APIScript {

    private api_root: string;

    constructor(api_root: string) {
        this.api_root = api_root;
    }

    async create(request: DataObjects.JSON_script_create_request): Promise<DataObjects.Script> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/create", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async delete(request: DataObjects.JSON_id_list_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/delete", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async get(request: DataObjects.JSON_id_list_request): Promise<(DataObjects.Script | null)[]> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/get", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async list(request: DataObjects.JSON_list_request): Promise<DataObjects.JSON_script_list_response> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/list", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json == null)
            return Promise.reject(result);
        else
            return json;
    }

    async which_page(request: DataObjects.JSON_which_page_request): Promise<DataObjects.JSON_which_page_response> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/which_page", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json === null)
            return Promise.reject(result);
        else
            return json;
    }

    async save(request: DataObjects.JSON_script_save_request): Promise<boolean> {
        var result: RequestResult = await request_fetch("POST", this.api_root + "/script/save", JSON.stringify(request), API_HEADERS_POST());
        var json: any = (result.status === 200) ? parseJSON(result) : null;
        if (json === null)
            return Promise.reject(result);
        else
            return json;
    }

    download_zip(id:number, _mode:SlideMode) {

        var mode = 'dark';
        if (_mode === SlideMode.Light)
            mode = 'light';
        
        RedirectNewPage(this.api_root + "/script/download_zip", {
            'id':id.toString(),
            'mode':mode
        });

    }

    download_pdf(id:number, _mode:SlideMode) {

        var mode = 'dark';
        if (_mode === SlideMode.Light)
            mode = 'light';
        
        RedirectNewPage(this.api_root + "/script/download_pdf", {
            'id':id.toString(),
            'mode':mode
        });

    }

}

class APIMainClass {
    private api_root: string = "http://localhost:8000";

    auth: APIAuth;
    music: APIMusic;
    script: APIScript;

    constructor() {
        if (window.location.hostname.toString() !== "localhost")
            this.api_root = this.api_root.replace('http://localhost:8000', window.location.protocol + "//" + window.location.hostname.toString() + ":" + window.location.port.toString());

        this.auth = new APIAuth(this.api_root);
        this.music = new APIMusic(this.api_root);
        this.script = new APIScript(this.api_root);
    }


}

export var API: APIMainClass = new APIMainClass();

