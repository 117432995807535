import { Button, Col, FloatingLabel, Form, InputGroup, OverlayTrigger, Pagination, Tooltip } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import loading_icon from "../../resources/loading_icon.gif";
import React, { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../../AppState";
import { API } from "../../API";
import * as DataObjects from "../../APIDataObjects"
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { blurParentAnchorOrButtonElement, createPaginationIndices } from "../../Util";
//import * as ReactDOM from 'react-dom';

function useQuery(): URLSearchParams {
    var location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export function SearchMusicCleanSearchParams(query: URLSearchParams, navigate: NavigateFunction, location: Location) {
    query.delete("search");
    query.delete("search_page");
    navigate(`.?${query.toString()}`, { replace: true, state: location.state });
}

export default function SearchMusic({
    onSelectMusic
}: {
    onSelectMusic: (music: DataObjects.Music) => void
}): JSX.Element {

    var loading_image_ref = useRef(loading_icon as any);

    var template_cell_ref = useRef(null as any);
    var [height, setHeight] = useState(325);

    var [isLoading, setIsLoading] = useState(false);

    var appState = useAppState();
    var setAppState = useSetAppState();

    var query = useQuery();
    var navigate = useNavigate();
    var location = useLocation();

    var searchInputItem = useRef<HTMLInputElement>(null);

    var [currentPage, setCurrentPage] = useState({
        page: -1,
        max_per_page: 0,
        page_count: 0,
        music_count: 0,
        musics: [] as DataObjects.Music[]
    } as DataObjects.JSON_music_list_response);

    var loadedPageState = useRef({
        searchStr: "",
        searchPage: ""
    });

    var asyncSearch = useCallback(async (
        searh_str: string,
        page: number) => {
            
            console.log("asyncSearch: ", searh_str);
            console.log("asyncSearch: ", page);

        setIsLoading(true);
        try {

            var newPage = await API.music.search({
                search_string: searh_str,
                page: page,
                max_per_page: 5,
            } as DataObjects.JSON_search_request);

            setCurrentPage(newPage);

            //navigate(`/music?page=${page}`);
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
            setAppState(appState);
        }

        setIsLoading(false);
    }, [appState, setAppState, setIsLoading, setCurrentPage]);

    var doSearch = useCallback(async () => {
        
        var input_value:string = searchInputItem.current?.value.toString()!;

        query.set("search", input_value);
        query.set("search_page", "0");
        navigate(`.?${query.toString()}`, { replace: true, state: location.state });

    }, [searchInputItem, query, navigate, location]);

    useEffect(() => {
        var client_height_total = parseInt(template_cell_ref.current.clientHeight) * 5;
        if (height !== client_height_total)
            setHeight(client_height_total);

        var searchStr: string | null = query.get("search");
        var searchPage: string | null = query.get("search_page");
        if (searchStr == null || searchPage == null) {
            query.set("search", "");
            query.set("search_page", "0");
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
            return;
        }

        if (loadedPageState.current.searchStr !== searchStr ||
            loadedPageState.current.searchPage !== searchPage) {
            loadedPageState.current = {
                searchStr: searchStr,
                searchPage: searchPage
            };
            asyncSearch(searchStr, parseInt(searchPage));
        }

    }, [height, query, navigate, location, asyncSearch]);



    var pageArray = createPaginationIndices(
        currentPage.page, currentPage.page_count,
        useCallback((btn:BaseSyntheticEvent, page: number) => {
            blurParentAnchorOrButtonElement(btn.target);
            query.set("search_page", page.toString());
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
        }, [query, navigate, location])
    );
    var pagePrevious = useCallback((btn:BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page > 0) {
            query.set("search_page", (currentPage.page - 1).toString());
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
        }
    }, [currentPage, query, location, navigate]);
    var pageNext = useCallback((btn:BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page < currentPage.page_count - 1) {
            query.set("search_page", (currentPage.page + 1).toString());
            navigate(`.?${query.toString()}`, { replace: true, state: location.state });
        }
    }, [currentPage, query, location, navigate]);

    return (<>
        <Col xs={12}>

            <div className="pt-1 position-absolute" style={{ visibility: "hidden" }} ref={template_cell_ref}>
                <a href="./#" onClick={(lnk) => { lnk.preventDefault(); }} className="h6 text-primary d-inline-block m-0">1. Música</a>
                <div className="text-secondary text-truncate d-block small" >Group/Company: A</div>
                <div className="text-secondary text-truncate d-block small" >Autor 1...</div>
            </div>

            <InputGroup className="mb-2">
                <FloatingLabel controlId="floatingTextarea" label="Pesquisar o nome da música">
                    <Form.Control className="rounded-4" placeholder="Pesquisar o nome da música" 
                        ref={searchInputItem as any}
                        onKeyDown={(keycode:any)=>{ 
                            if (keycode.code === "Enter"){
                                console.log("Hit Enter...");
                                doSearch();
                            }
                        }}
                    />
                </FloatingLabel>
                <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-search"}>Pesquisar</Tooltip>}>
                    <Button onClick={() => { 
                        console.log("Clicou Pesquisar...");
                        doSearch();
                    }} className="rounded-circle p-2 align-self-center ms-2" style={{ height: "fit-content" }}>
                        <div className="d-flex align-items-center">
                            <FaSearch size={20} />
                        </div>
                    </Button>
                </OverlayTrigger>
            </InputGroup>
            <div style={{ minHeight: height }} className="d-flex flex-column">

                {(isLoading) ? (
                    <img className="m-auto" src={loading_image_ref.current} style={{ objectFit: "scale-down", userSelect: "none", width: 50 }} alt="loading" ></img>
                ) : (
                    currentPage.musics.map(function (music: DataObjects.Music, i) {
                        // authors array
                        var authors = "";
                        for (var author of music.authors) {
                            if (authors.length > 0)
                                authors = authors + ", " + author;
                            else
                                authors = author;
                        }
                        if (authors.length === 0)
                            authors = "Autor não cadastrado";
                        return (
                            <div className="pt-1" key={i}>
                                <a href="./#" onClick={(lnk) => { lnk.preventDefault(); onSelectMusic(music); }} className="h6 text-primary d-inline-block m-0">{i + 1}. {music.name}</a>
                                <div className="text-secondary text-truncate d-block small" >{authors}&nbsp;</div>
                                <div className="text-secondary text-truncate d-block small" >Possui {music.slides.length} slides.</div>
                            </div>
                        )
                    })
                )}
            </div>
            <Col className="d-flex justify-content-md-center justify-content-center mt-3">
                <Pagination size="sm">
                    <Pagination.Prev onClick={pagePrevious} />
                    {pageArray}
                    <Pagination.Next onClick={pageNext} />
                </Pagination>
            </Col>

        </Col>
    </>);
}