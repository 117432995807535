import { Button, Container, Form } from "react-bootstrap";
import Footer from "./Layout/Footer";
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState";
import { BaseSyntheticEvent, useEffect, useRef } from "react";
import { LockButtonEvent_DropMode } from "../Util";
import { API } from "../API";
import * as DataObjects from "../APIDataObjects";
import Header from "./Layout/Header";
import { useNavigate } from "react-router-dom";


export default function Login(): JSX.Element {
    var appState = useAppState();
    var setAppState = useSetAppState();
    var navigate = useNavigate();

    const usernameRef = useRef(null as any);
    const passwordRef = useRef(null as any);

    // useEffect acts like componentDidMount
    useEffect(()=>{
        if (appState.isLoggedIn)
            navigate('/');
    });
    if (appState.isLoggedIn)
        return <></>;

    function onClickLogin(event: BaseSyntheticEvent) {
        event.preventDefault();
        LockButtonEvent_DropMode(async () => {

            appState.isToShowLoadingModal = true;
            appState.appMessages = [];
            setAppState(appState);
            try {

                await API.auth.login({
                    username: usernameRef.current.value,
                    password: passwordRef.current.value
                } as DataObjects.JSON_auth_login_request);

                appState.isLoggedIn = true;
                navigate("/");
            } catch (err: any) {
                appState.appMessages.push(createAppMessageErrorFromCatch(err));
            }

            appState.isToShowLoadingModal = false;
            setAppState(appState);
        });
    }

    return (
        <>
            <Header title="Cristo Ilumina" />
            <main className="flex-shrink-0 flex-grow-1">
                {/* <Container className="px-3 pb-3 pt-3-navbar-spacer"> */}
                <Container className="p-3 d-flex h-100">
                    <Form className="col-md-5 mx-auto my-auto">
                        <h1 className="h3 mb-3 fw-normal text-center">Login</h1>

                        <Form.Group className="form-floating" controlId="username">
                            <Form.Control ref={usernameRef} type="text" placeholder="Usuário" />
                            <Form.Label>Usuário</Form.Label>
                        </Form.Group>

                        <Form.Group className="form-floating mt-1" controlId="password">
                            <Form.Control ref={passwordRef} type="password" placeholder="Senha" />
                            <Form.Label>Senha</Form.Label>
                        </Form.Group>

                        <Button variant="primary" onClick={onClickLogin} className="w-100 py-2 mt-4" type="submit">Entrar</Button>
                    </Form>
                </Container>
            </main>
            <Footer />
        </>
    );
}
