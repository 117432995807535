import { BaseSyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Col, Container, Modal, OverlayTrigger, Pagination, Row, Tooltip } from "react-bootstrap"
import { FaDownload, FaFileArchive, FaFilePdf, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import { API, SlideMode } from "../API"
import * as DataObjects from "../APIDataObjects"
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState"
import { blurParentAnchorOrButtonElement, createPaginationIndices } from "../Util"
import Footer from "./Layout/Footer"
import Header from "./Layout/Header"
import "./Script.css"

function useQuery(): URLSearchParams {
    var location = useLocation();
    return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export default function Script(): JSX.Element {
    var navigate = useNavigate()
    var appState = useAppState()
    var setAppState = useSetAppState()
    var location = useLocation()
    var query = useQuery()

    var [showModal, setShowModal] = useState(false);
    var scriptRef = useRef({
        id: -1,
        name: "",
        musics: [] as DataObjects.Music[]
    } as DataObjects.Script);

    var [showModalDownload, setShowModalDownload] = useState(false);


    var [currentPage, setCurrentPage] = useState({
        page: -1,
        max_per_page: 0,
        page_count: 0,
        script_count: 0,
        scripts: [] as DataObjects.Script[]
    } as DataObjects.JSON_script_list_response);

    var pageArray = createPaginationIndices(
        currentPage.page, currentPage.page_count,
        (btn: BaseSyntheticEvent, page: number) => {
            blurParentAnchorOrButtonElement(btn.target);
            navigate(`/script?page=${page}`);
            window.scrollTo(0, 0);
        }
    );
    var pagePrevious = useCallback((btn: BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page > 0) {
            navigate(`/script?page=${currentPage.page - 1}`);
            window.scrollTo(0, 0);
        }
    }, [currentPage, navigate]);
    var pageNext = useCallback((btn: BaseSyntheticEvent) => {
        blurParentAnchorOrButtonElement(btn.target);
        if (currentPage.page < currentPage.page_count - 1) {
            navigate(`/script?page=${currentPage.page + 1}`);
            window.scrollTo(0, 0);
        }
    }, [currentPage, navigate]);

    // var pageArray = [];
    // if (currentPage.page >= 0) {
    // 	var init_page = 1;
    // 	var end_page = 1;

    // 	var dst_zero = currentPage.page;
    // 	var dst_last = (currentPage.page_count - 1) - currentPage.page;
    // 	if (dst_zero < dst_last) {
    // 		init_page = currentPage.page + 1 - 2;
    // 		if (init_page < 1)
    // 			init_page = 1;
    // 		end_page = init_page + 5;
    // 		if (end_page > currentPage.page_count + 1)
    // 			end_page = currentPage.page_count + 1;
    // 	} else {
    // 		end_page = currentPage.page + 1 + 3;
    // 		if (end_page > currentPage.page_count + 1)
    // 			end_page = currentPage.page_count + 1;
    // 		init_page = end_page - 5;
    // 		if (init_page < 1)
    // 			init_page = 1;
    // 	}

    // 	for (var i = init_page; i < end_page; i++) {
    // 		((real_index: number) => {
    // 			if (real_index === currentPage.page)
    // 				pageArray.push(<Pagination.Item onClick={() => { navigate(`/script?page=${real_index}`); }} key={real_index} active>{real_index + 1}</Pagination.Item>);
    // 			else
    // 				pageArray.push(<Pagination.Item onClick={() => { navigate(`/script?page=${real_index}`); }} key={real_index}>{real_index + 1}</Pagination.Item>);
    // 		})(i - 1);
    // 	}
    // }

    var asyncLoadCallback = useCallback(async (page: number) => {
        console.log("asyncLoadCallback: ", page);

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {

            var page_to_load = page;
            //console.log("location.state: ",location.state);
            if (location.state != null && location.state.scriptIdToShow != null) {
                //search for page ID
                //do search and navigete with the returned page


                console.log("... redirecting ...");

                var rc = await API.script.which_page({
                    id: parseInt(location.state.scriptIdToShow),
                    max_per_page: 5,
                } as DataObjects.JSON_which_page_request);
                page_to_load = rc.page;

                //console.log("redirecting to page: ", page_to_load);

                navigate(`/script?page=${page_to_load}`, { replace: true, state: null });
            }

            if (page_to_load === page) {

                var newPage = await API.script.list({
                    page: page,
                    max_per_page: 5,
                } as DataObjects.JSON_list_request);
                setCurrentPage(newPage);

            }

            //navigate(`/music?page=${page}`);
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);
    }, [appState, setAppState, setCurrentPage, location, navigate]);

    var deleteScript = useCallback(async (id: number) => {

        console.log("deleteScript: ", id);

        var need_reload = false;

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            await API.script.delete({
                id_list: [id]
            } as DataObjects.JSON_id_list_request);

            need_reload = true;

        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);

        if (need_reload)
            await asyncLoadCallback(currentPage.page);

    }, [appState, setAppState, asyncLoadCallback, currentPage]);

    useEffect(
        () => {
            if (appState.isInitializing)
                return;
            if (!appState.isLoggedIn) {
                navigate("/login");
                return;
            }

            var intPage: number = 0;
            var queryPage: string | null = query.get("page");
            if (queryPage == null) {
                //navigate(`/music?page=0`);
                navigate(`/script?page=0`, { replace: true, state: location.state });
                return;
            }
            intPage = parseInt(queryPage);

            if (intPage !== currentPage.page) {

                //console.log("page different: ", intPage, currentPage.page);

                // avoid infinity reload with different page number...
                currentPage.page = intPage;
                setCurrentPage(Object.assign({}, currentPage));

                asyncLoadCallback(intPage);
            }
        },
        [appState, setCurrentPage, asyncLoadCallback, currentPage, navigate, query, location]
    );

    return (
        <>
            <Modal centered={false} show={showModalDownload} onHide={() => { setShowModalDownload(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Download</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-3">
                    <Row>
                        Slides:
                    </Row>
                    <Row className="my-2">
                        <Col xs={6} className="d-flex align-items-center justify-content-center p-0">
                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip className="custom-tooltip" style={{ position: "fixed", whiteSpace: "nowrap" }} id={"tooltip-disabled-modal-download-zip-light"}>script_{scriptRef.current.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[ ]/g, '_').toLowerCase()}_light.zip</Tooltip>}>
                                <Button variant="primary" onClick={() => {
                                    API.script.download_zip(scriptRef.current.id, SlideMode.Light);
                                    setShowModalDownload(false);
                                }} className="border-0 w-auto">
                                    <FaFileArchive size={20} /> JPGs - Tema Claro
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-center p-0">
                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip className="custom-tooltip" style={{ position: "fixed", whiteSpace: "nowrap" }} id={"tooltip-disabled-modal-download-zip-dark"}>script_{scriptRef.current.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[ ]/g, '_').toLowerCase()}_dark.zip</Tooltip>}>
                                <Button variant="primary" onClick={() => {
                                    API.script.download_zip(scriptRef.current.id, SlideMode.Dark);
                                    setShowModalDownload(false);
                                }} className="border-0 w-auto">
                                    <FaFileArchive size={20} /> JPGs - Tema Escuro
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row className="my-2">
                        <Col xs={6} className="d-flex align-items-center justify-content-center p-0">
                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip className="custom-tooltip" style={{ position: "fixed", whiteSpace: "nowrap" }} id={"tooltip-disabled-modal-download-zip-light"}>script_{scriptRef.current.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[ ]/g, '_').toLowerCase()}_light.zip</Tooltip>}>
                                <Button variant="primary" onClick={() => {
                                    API.script.download_pdf(scriptRef.current.id, SlideMode.Light);
                                    setShowModalDownload(false);
                                }} className="border-0 w-auto">
                                    <FaFilePdf size={20} /> PDF - Tema Claro
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={6} className="d-flex align-items-center justify-content-center p-0">
                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip className="custom-tooltip" style={{ position: "fixed", whiteSpace: "nowrap" }} id={"tooltip-disabled-modal-download-zip-dark"}>script_{scriptRef.current.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[ ]/g, '_').toLowerCase()}_dark.zip</Tooltip>}>
                                <Button variant="primary" onClick={() => {
                                    API.script.download_pdf(scriptRef.current.id, SlideMode.Dark);
                                    setShowModalDownload(false);
                                }} className="border-0 w-auto">
                                    <FaFilePdf size={20} /> PDF - Tema Escuro
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModalDownload(false); }}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal centered={false} show={showModal} onHide={() => { setShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Remover</Modal.Title>
                </Modal.Header>
                <Modal.Body>Deseja remover o script '{scriptRef.current.name}' ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={async () => { setShowModal(false); await deleteScript(scriptRef.current.id); }}>
                        Remover
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header title="Cristo Ilumina" />
            <main className="flex-shrink-0">
                {/* <Container className="px-3 pb-3 pt-3-navbar-spacer"> */}
                <Container className="pt-3">
                    <Row className="align-items-center mb-3 p-0">
                        <Col xs={"auto"} className="h2">
                            Scripts
                        </Col>
                        <Col className="d-flex flex-row-reverse">
                            <Button onClick={() => { navigate('/script/edit'); }} className="d-flex align-items-center">
                                <div className="d-flex pe-2">
                                    <FaPlus />
                                </div>
                                Novo script
                            </Button>
                        </Col>
                    </Row>
                    <Row
                        className="header-table fw-bold py-0"
                        style={{ backgroundColor: "#e2e3e5" }}
                    >
                        <Col xs={6} md={8} lg={9}
                            className="py-2 d-flex align-items-center">
                            Nome
                        </Col>

                        <Col xs={6} md={4} lg={3}
                            className="d-flex justify-content-center border-start border-secondary py-2">
                            Ações
                        </Col>
                    </Row>
                    {currentPage.scripts.map(function (script: DataObjects.Script, i) {
                        var total_slides = 0;
                        for (var music of script.musics) {
                            total_slides += music.slides.length;
                        }
                        return (
                            <Row key={i} className="py-0 table-music align-items-stretch">
                                <Col xs={6} md={8} lg={9} className="fs-6 py-0">
                                    <div className="pt-2">{script.name}</div>
                                    <div className="text-secondary text-truncate d-block small pb-2" >Possui {script.musics.length} música(s) e {total_slides} slide(s).</div>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="py-0 border-start border-secondary d-flex justify-content-center align-items-center">
                                    <Row xs={"auto"} className="py-2 gx-1 d-flex justify-content-center align-items-baseline">
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-edit-" + script.id.toString()}>Download</Tooltip>}>
                                                <Button onClick={() => { scriptRef.current = script; setShowModalDownload(true); }} className="border-0 text-success bg-light">
                                                    <FaDownload size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-edit-" + script.id.toString()}>Editar</Tooltip>}>
                                                <Button onClick={() => { navigate(`/script/edit/${script.id}`); }} className="border-0 text-success bg-light">
                                                    <FaPencilAlt size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-remove-" + i}>Remover</Tooltip>}>
                                                <Button onClick={() => { scriptRef.current = script; setShowModal(true); }} className="border-0 text-danger bg-light">
                                                    <FaTrash size={20} />
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}

                    <Row className="py-4">
                        <Col className="d-flex flex-row-reverse">
                            <Button onClick={() => { navigate('/script/edit'); }} className="d-flex align-items-center">
                                <div className="d-flex pe-2">
                                    <FaPlus />
                                </div>
                                Novo script
                            </Button>
                        </Col>
                    </Row>
                    <Row className="d-flex">
                        <Col xs={12} md={6} className="pb-2 d-flex justify-content-md-start justify-content-center">
                            <div>
                                Mostrando <b>{currentPage.scripts.length}</b> de <b>{currentPage.script_count}</b> scripts.
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-md-end justify-content-center">
                            <Pagination>
                                <Pagination.Prev onClick={pagePrevious} />
                                {pageArray}
                                <Pagination.Next onClick={pageNext} />
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    )
}
