
export class RequestResult {
    url: string = "";
    headers: { [name: string]: string; } = {};
    status: number = 999;//-1;
    body: string = "";
    error: string | null = null;

    public constructor(init?: Partial<RequestResult>) {
        Object.assign(this, init);
    }
};

export async function request_fetch(method: string, url: string, body: string, headers:{[name:string]:string}|null = null): Promise<RequestResult> {
    var myHeaders: Headers = new Headers();

    //myHeaders.append("Access-Control-Allow-Origin", "*");
    //myHeaders.append("Access-Control-Request-Method", method);
    //myHeaders.append("Access-Control-Request-Headers", "Content-Type");

    if (headers != null){
        for(var key in headers){
            myHeaders.append(key, headers[key]);
        }
    }
    //if (method == "POST")
    //myHeaders.append("Content-Type", "application/json")
    var init: RequestInit = {
        method: method,
        headers: myHeaders,
        mode: 'cors',
        credentials: 'include',
        body: body,
        cache: 'default',
        //referrerPolicy: 'strict-origin'
    };
    if (method === "GET" || method === "HEAD") {
        delete init.body;
    }
    var data: RequestResult = new RequestResult({
        url: url,
        headers: {},
        status: 999,//-1,
        body: "",
        error: null
    });

    var res: Response | null = null;
    try {
        res = await fetch(url, init);
    } catch (err: any) {
        data.status = 999;//-1;
        if (err instanceof Error) {
            var _error: Error = err;
            data.error = "Connection Error (" + url + "): " + _error.message;
        } else {
            data.error = "Connection Error (" + url + "): unknown error.";
        }
        //console.error(err);
    }

    if (data.error != null)
        return data;

    if (res == null) {
        data.status = 999;//-1;
        data.error = "Fetch returned NULL.";
        return data;
    }

    data.status = res.status;
    //data.headers = {};

    var it: IterableIterator<[string, string]> = res.headers.entries();
    var it_q: IteratorResult<[string, string], any>;
    it_q = it.next();
    while (!it_q.done) {
        var pair = it_q.value;
        data.headers[pair[0]] = pair[1];
        it_q = it.next();
    }

    if (res.status >= 400) {
        var msg: string = await res.text();
        if (msg === "" || msg === null) {
            msg = "Erro ao invocar url:" +
                method + " " + url + " => " +
                res.status + ":" +
                res.statusText;
        }
        data.error = msg;
        //return Promise.reject(msg);
        return data;
    }
    data.body = await res.text();
    return data;
}

export function parseJSON(result: RequestResult): any | null {
    if (result.body.length === 0) {
        result.status = 999;//-1;
        result.error = "Json Parse Error (" + result.url + "): Empty body.";
        return null;
    }
    try {
        return JSON.parse(result.body);
    } catch (err: any) {
        result.status = 999;//-1;
        if (err instanceof Error) {
            var _error: Error = err;
            result.error = "Json Parse Error (" + result.url + "): " + _error.message;
        } else {
            result.error = "Json Parse Error (" + result.url + "): unknown error.";
        }
    }
    return null;
}