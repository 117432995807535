import { Container, Nav, Navbar } from "react-bootstrap";
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../../AppState";
import { BaseSyntheticEvent, CSSProperties } from "react";
import { LockButtonEvent_DropMode } from "../../Util";
import { API } from "../../API";
import DrawAlertMessages from "./DrawAlertMessages";
import { useNavigate } from "react-router-dom";
import logo_30 from '../../resources/logo_30.png';

// interface HeaderProperties {
//     title: string;
//     appState: AppState;
// };

export default function Header({title}: {
    title: string;
}): JSX.Element {

    var appState = useAppState();
    var setAppState = useSetAppState();
    var navigate = useNavigate();


    function onClickLogout(event: BaseSyntheticEvent) {
        event.preventDefault();
        LockButtonEvent_DropMode(async () => {
            appState.isToShowLoadingModal = true;
            appState.appMessages = [];
            setAppState(appState);

            try {
                await API.auth.logout();

                appState.isLoggedIn = false;
                navigate("/login");
            } catch (err: any) {
                appState.appMessages.push(createAppMessageErrorFromCatch(err));
            }

            appState.isToShowLoadingModal = false;
            setAppState(appState);

        });
    }


    var fixed_top_navbar_min_height_style = {
        minHeight:"60px",
    } as CSSProperties;

    return (
        <>
        {/* <header className="fixed-top-navbar-min-height"> */}
        <header style={fixed_top_navbar_min_height_style}>
            <Navbar expand="md" className="bg-body-tertiary fixed-top">
                <Container>
                    <Navbar.Brand href="#/" onClick={() => { }}><img src={logo_30} alt="logo" width={30} height={30}></img>  {title}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            {appState.isLoggedIn ? (
                                <>
                                    <Nav.Link href="#/music">Músicas</Nav.Link>
                                    <Nav.Link href="#/script">Scripts</Nav.Link>
                                    <Nav.Link onClick={onClickLogout} href="#/logout">Logout</Nav.Link>
                                </>
                            ) : (
                                <>
                                    <Nav.Link href="#/login">Login</Nav.Link>
                                </>
                            )}
                            {/* <NavDropdown title="Usuário" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">Logout</NavDropdown.Item>
                            </NavDropdown> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
        <DrawAlertMessages />
        </>



    );
}