import React, { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FaArrowDown, FaArrowUp, FaPlus, FaTrash } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API } from "../API";
import * as DataObjects from "../APIDataObjects";
import { createAppMessageErrorFromCatch, useAppState, useSetAppState } from "../AppState";
//import loading_icon from "../resources/loading_icon.gif";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import SearchMusic, { SearchMusicCleanSearchParams } from "./Layout/SearchMusic";

function useQuery(): URLSearchParams {
    var location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export default function ScriptEdit(): JSX.Element {

    var appState = useAppState();
    var setAppState = useSetAppState();

    var params = useParams();
    var loadedIDRef = useRef("-1");

    var query = useQuery();
    var navigate = useNavigate();
    var location = useLocation();

    //var stringTextToPreviewRef = useRef("");
    var [showModal, setShowModal] = useState(false);
    var onModalClose = useCallback(() => {
        SearchMusicCleanSearchParams(query, navigate, location);
    }, [query, navigate, location]);

    var executeOnce = useRef(true);

    var [showTrashModal, setShowTrashModal] = useState(false);
    var modal_delete_ref = useRef(-1 as number)

    var isToCreate = params.id == null;

    var script: DataObjects.Script = {
        id: -1,
        name: "",
        musics: [] as DataObjects.Music[]
    } as DataObjects.Script;

    if (location.state != null && location.state !== script) {
        script = Object.assign({}, location.state);
        loadedIDRef.current = script.id.toString();
    }

    var loadCallback = useCallback(async (id: number) => {
        console.log("loadCallback: ", id);

        appState.isToShowLoadingModal = true;
        setAppState(appState);

        try {
            var newData: (DataObjects.Script | null)[] = await API.script.get({ id_list: [id] } as DataObjects.JSON_id_list_request);
            if (newData.length > 0 && newData[0] !== null) {
                navigate(".", {
                    replace: true, state: newData[0]
                });
            }
        } catch (err: any) {
            appState.appMessages.push(createAppMessageErrorFromCatch(err));
        }

        appState.isToShowLoadingModal = false;
        setAppState(appState);

    }, [appState, setAppState, navigate]);

    useEffect(() => {
        if (appState.isInitializing)
            return;
        if (!appState.isLoggedIn) {
            navigate("/login");
            return;
        }

        if (!isToCreate && params.id != null && loadedIDRef.current !== params.id) {
            loadedIDRef.current = params.id
            loadCallback(parseInt(params.id))
        }

        //check if modal is opened
        if (executeOnce.current && !showModal) {
            executeOnce.current = false;
            var searchStr: string | null = query.get("search");
            var searchPage: string | null = query.get("search_page");
            if (searchStr != null && searchPage != null)
                setShowModal(true);
        }

        return () => {
            //saveHistory();
        }
    }, [isToCreate, appState, location, params, showModal, setShowModal, query, navigate, loadCallback, loadedIDRef, executeOnce]);

    function saveHistory(){
        navigate(".", { replace: true, state: Object.assign({}, script) });
    }

    function onclickBackButton() {
        if (loadedIDRef.current === "-1")
            navigate("/script")
        else
            navigate(`/script`, {
                state: {
                    scriptIdToShow: parseInt(loadedIDRef.current),
                },
            })
    }

    function onSelectMusic (music: DataObjects.Music) {
        setShowModal(false);
        script.musics.push(music);
        saveHistory();
    }

    var createCallback = useCallback(
		async (_script: DataObjects.Script) => {
			console.log("createCallback: ", _script)

			appState.isToShowLoadingModal = true
			setAppState(appState)

			try {
                var id_list:number[] = [];
                for(var _m of _script.musics)
                    id_list.push(_m.id);
				var created_script = await API.script.create({
                    name: _script.name,
                    music_id_list: id_list
                } as DataObjects.JSON_script_create_request);
				navigate(`/script`, {
					state: {
						scriptIdToShow: created_script.id,
					},
				})
			} catch (err: any) {
				appState.appMessages.push(createAppMessageErrorFromCatch(err))
			}

			appState.isToShowLoadingModal = false
			setAppState(appState)
		},
		[appState, setAppState, navigate]
	)

    var saveScriptCallback = useCallback(
		async (_script: DataObjects.Script) => {
            if (params.id == null) return;
			console.log("saveScriptCallback: ", _script);

			appState.isToShowLoadingModal = true;
			setAppState(appState);

			try {
                var id_list:number[] = [];
                for(var _m of _script.musics)
                    id_list.push(_m.id);
				await API.script.save({
                    id: parseInt(params.id),
                    name: _script.name,
                    music_id_list: id_list
                } as DataObjects.JSON_script_save_request);
				navigate(`/script`, {
					state: {
						scriptIdToShow: parseInt(params.id),
					},
				})
			} catch (err: any) {
				appState.appMessages.push(createAppMessageErrorFromCatch(err))
			}

			appState.isToShowLoadingModal = false
			setAppState(appState)
		},
		[appState, setAppState, navigate, params]
	)

    return (
        <>
            <Modal show={showTrashModal} onHide={() => setShowTrashModal(false)}>
                <Modal.Header>
                    <Modal.Title>Remover</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Remover a música '{(modal_delete_ref.current in script.musics)&&script.musics[modal_delete_ref.current].name}' ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowTrashModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={() => { script.musics.splice(modal_delete_ref.current, 1); saveHistory(); setShowTrashModal(false); }}>
                        Apagar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal onExited={onModalClose} centered={false} show={showModal} onHide={() => { setShowModal(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Selecione uma Música</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex content-justify-center pb-0">
                    <SearchMusic onSelectMusic={onSelectMusic} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>
                        Cancelar
                    </Button>
                </Modal.Footer>
            </Modal>
            <Header title="Cristo Ilumina" />
            <main>
                 <Container className="my-3"> {/*col-md-8 col-lg-6 */}
                    <Row className="mb-3">
                        <Col xs={12} className="mb-3">
                            {isToCreate ? (
                                <div className="h3 mb-3 text-center">Adicionar Script</div>
                            ) : (
                                <div className="h3 mb-3 text-center">Editar Script</div>
                            )}
                            <Form>
                                <Form.Group className="form-floating" controlId="script_name">
                                    <Form.Control type="text" placeholder="Nome do script" value={script.name} onChange={(v: BaseSyntheticEvent) => {
                                        script.name = v.target.value;
                                        saveHistory();
                                    }} />
                                    <Form.Label>Nome do script</Form.Label>
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col xs={"auto"} className="d-flex align-self-end ms-auto">
                            <Button onClick={() => { setShowModal(true) }} className="d-flex align-items-center">
                                <div className="d-flex pe-1">
                                    <FaPlus />
                                </div>
                                Adicionar música
                            </Button>
                        </Col>
                    </Row>

                    <Row className="header-table fw-bold py-0" style={{ backgroundColor: "#e2e3e5" }}>
                        <Col xs={6} md={8} lg={9} className="py-2 d-flex align-items-center">
                            Seqüência de músicas
                        </Col>

                        <Col xs={6} md={4} lg={3} className="d-flex justify-content-center border-start border-secondary py-2">
                            Ações
                        </Col>
                    </Row>
                    {script.musics.map(function (_music:DataObjects.Music, i) {
                        var authors = "";
                        for (var author of _music.authors) {
                            if (authors.length > 0)
                                authors = authors + ", " + author;
                            else
                                authors = author;
                        }
                        if (authors.length === 0)
                            authors = "Autor não cadastrado";
                        return (
                            <Row key={i} className="py-0 table-music align-items-stretch">
                                <Col xs={6} md={8} lg={9} className="fs-6 py-0">
                                    <div className="pt-2">{i+1}. {_music.name}</div>
                                    <div className="text-secondary text-truncate d-block small" >{authors}</div>
                                    <div className="text-secondary text-truncate d-block small pb-2" >Possui {_music.slides.length} slide(s).</div>
                                </Col>
                                <Col xs={6} md={4} lg={3} className="py-0 border-start border-secondary d-flex align-items-center justify-content-center">
                                    <Row xs={"auto"} className="py-2 gx-1 d-flex justify-content-center align-items-baseline">

                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-remove-" + _music.id.toString()}>Remover</Tooltip>}>
                                                <Button onClick={() => { modal_delete_ref.current = i; setShowTrashModal(true) }} className="border-0 text-danger bg-light">
                                                    <div className="d-flex align-items-center">
                                                        <FaTrash />
                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-moveup-" + i.toString()}>Mover para cima</Tooltip>}>
                                                <Button disabled={(i <= 0)} onClick={() => { var aux = script.musics[i]; script.musics[i] = script.musics[i - 1]; script.musics[i - 1] = aux; saveHistory(); }} className="border-0 bg-warning">
                                                    <div className="d-flex align-items-center">
                                                        <FaArrowUp color="black" />
                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                        <Col xs={"auto"}>
                                            <OverlayTrigger trigger={["hover", "hover"]} overlay={<Tooltip style={{ position: "fixed" }} id={"tooltip-disabled-movedown-" + i.toString()}>Mover para baixo</Tooltip>}>
                                                <Button disabled={(i >= script.musics.length - 1)} onClick={() => { var aux = script.musics[i]; script.musics[i] = script.musics[i + 1]; script.musics[i + 1] = aux; saveHistory(); }} className=" border-0 bg-warning">
                                                    <div className="d-flex align-items-center">
                                                        <FaArrowDown color="black" />
                                                    </div>
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })}
                    <Row className="mt-3">
                        <Col xs={"auto"} className="d-flex align-self-end ms-auto">
                            <Button onClick={() => { setShowModal(true) }} className="d-flex align-items-center">
                                <div className="d-flex pe-1">
                                    <FaPlus />
                                </div>
                                Adicionar música
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mx-auto mt-3 gx-1 d-flex justify-content-center">
                        <Col xs={"auto"}>
                            <Button variant="secondary" onClick={onclickBackButton}>
                                Voltar
                            </Button>
                        </Col>
                        <Col xs={"auto"}>
                            {isToCreate ? (
                                <Button onClick={async () => {
                                    await createCallback(script);
                                }}
                                    variant="success">
                                    Salvar
                                </Button>
                            ) : (
                                <Button
                                    onClick={async () => {
                                        await saveScriptCallback(script);
                                    }}
                                    variant="success">
                                    Atualizar
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    )
}
