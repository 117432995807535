import { Col, Container, Row } from "react-bootstrap";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import logo from "../resources/logo.png"


export function Home(): JSX.Element {
    //var appState: AppState = useAppState();
    return (
        <>
            <Header title="Cristo Ilumina" />
            <main className="flex-shrink-0">
                {/* <Container className="px-3 pb-3 pt-3-navbar-spacer"> */}
                <Container className="p-3">

                    <Row className="justify-content-center">
                        <Col xs="auto"><img src={logo} alt="logo" width={138} height={138}></img></Col>
                        <Col xs="auto" className="align-middle my-auto text-start py-3">
                            <h2 className="m-0 p-0">Cristo Ilumina</h2>
                            <span className="lead m-0 p-0">Assistente Multimídia</span>
                        </Col>
                    </Row>

                </Container>
            </main>
            <Footer />
        </>
    );
}
