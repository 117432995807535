//import React from 'react';
//import logo from './logo.svg';
import { Route, HashRouter as Router, Routes } from "react-router-dom"
import "./App.css"
import { AppStateComponent } from "./AppState"
import { Home } from "./Pages/Home"
import Login from "./Pages/Login"
import Music from "./Pages/Music"
import MusicEdit from "./Pages/MusicEdit"
import MusicSlide from "./Pages/MusicSlide"
import NotFound from "./Pages/NotFound"
import Script from "./Pages/Script"
import ScriptEdit from "./Pages/ScriptEdit"
/*
        // <div className="App">
        //   <header className="App-header">
        //     <img src={logo} className="App-logo" alt="logo" />
        //     <p>
        //       Edit <code>src/App.tsx</code> and save to reload.
        //     </p>
        //     <a
        //       className="App-link"
        //       href="https://reactjs.org"
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     >
        //       Learn React
        //     </a>
        //   </header>
        // </div>
*/

function App(): JSX.Element {
    return (
        <Router>
            <AppStateComponent>
                <Routes>
                    <Route index element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="music" element={<Music />} />
                    <Route path="music/edit/:id?" element={<MusicEdit />} />
                    <Route path="music/slide/:id" element={<MusicSlide />} />
                    <Route path="script" element={<Script />} />
                    <Route path="script/edit/:id?" element={<ScriptEdit />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AppStateComponent>
        </Router>
    )
}

export default App
